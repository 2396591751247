@import "variables.scss";
@import "colors.scss";

html {
  body {
    .ui.modals {
      .ui.modal {
        padding: 40px;
        > .close {
          top: 40px;
          right: 40px;
          color: #000000;
          font-size: unset;
        }

        .ui.header {
          font-family: FuturaStd-Heavy;
          font-size: 24px;
          color: $color-text-alternative1;
        }
      }
    }
  }
}
