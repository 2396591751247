@media only screen and (max-width: 1299px) {
  .wide-videos {
    display: none;
  }

  .narrow-videos {
    display: auto;
  }
}

@media only screen and (min-width: 1300px) {
  .wide-videos {
    display: auto;
  }

  .narrow-videos {
    display: none;
  }
}
