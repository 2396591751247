@import "../../../../styles/colors.scss";

body > div.ui.page.modals.dimmer.transition.visible.active > div {
  .ms-flex-secondary {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ms-hundred-percent-width {
    width: 100% !important;
  }

  .ms-btn-overwrite {
    background-color: $color-warning-background !important;
    color: $color-warning-text !important;
    border-color: $color-orange0 !important;
  }

  .ms-modal-actions {
    background-color: white !important;
    border-top: none !important;
  }

  .ms-warning-message {
    background-color: $color-warning-background !important;
    color: $color-warning-text !important;
    box-shadow: 0 0 0 1px $color-orange0 inset, 0 0 0 0 transparent !important;
  }
}

.ms-margin-bottom-2 {
  margin-bottom: 2em;
}

.ms-margin-top-5 {
  margin-top: 5em;
}

.ms-float-search-right {
  float: right;
}
