.icon-margin {
  margin-left: 1em;
  padding: none;
  margin-right: none;
}

button {
  .flex {
    display: flex;
    align-items: center;
    max-height: 1em;
    margin-top: 0.1em;
    justify-content: center;
  }
}