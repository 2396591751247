@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.ui.inverted.menu.menu.mobile-sidebar-container {
  display: grid;
  background-color: $color-background2;
  width: 270px;
  margin-top: -20px;
  grid-area: menu;
  height: 100%;
  flex-direction: row;
  z-index: 7000;
  position: fixed;
  top: 0;
  left: 0;

  .menu-header {
    padding-left: 30px;
    height: 63px;
    background-color: $color-dark-blue0;
    padding-top: 12px;
    .company-logo {
      float: left;
      width: 40px;
      height: 40px;
    }
    .icon {
      float: right;
      padding-right: 10px;
      padding-top: 10px;
      cursor: pointer;
    }
    .white {
      color: white !important;
    }
  }
  .menu-content {
    .item.menu-item {
      padding-left: 30px;
      background-color: $color-background1;
      color: $color-text-alternative1;
      font-size: 16px;
      border-bottom: 1px solid #eff0f0;
      flex-direction: row;
      font-family: FuturaStd-Medium;
    }
    .item.menu-item.active-left-nav,
    .item.menu-item.active-left-nav:hover {
      font-family: FuturaStd-Heavy;
    }
    padding-bottom: 200px;
    background-color: $color-background1;
  }
  .menu-footer {
    padding-left: 30px;
    background-color: $color-background2;
    text-align: left;
    .account-settings {
      padding-top: 26px;
      font-family: FuturaStd-Heavy;
      text-decoration: underline;
      a {
        color: $color-text-alternative1;
      }
    }
    .log-out {
      padding-right: 30px;
      padding-top: 26px;
      button {
        width: 100%;
        color: $color-text-alternative1;
        background: $color-background3;
        border: 1px solid $color-text-alternative1;
      }
    }
  }
}
