iframe.embedded {
  border: none;
  min-height: 600px;
}

.text-center {
  text-align: center;
}

.report {
  width: 100%;
  height: 100%;
  /* HACK: This is for testing purposes until enough time is available to fix the styling.*/
  height: 600px;
}
