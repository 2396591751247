@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
div.collapse-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  button.ui.button.collapse-link {
    background: none;
    border: none;
    box-shadow: none;
    font: FuturaStd-Medium;
    font-size: 12px;
    color: $color-light-blue0;
    display: flex;
    align-items: center;
    padding-right: 0;
    margin-right: 0;
  }
}
