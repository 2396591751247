@import "../../styles/colors.scss";

.ui.grid> .row> .column.modal-action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  > .modal-action-button {
    width: 50%;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.sub-header {
  font-family: FuturaStd-Medium !important;
  color: #2c2c2c !important;
  font-size: 20px !important;
}

.modal {
  padding: 40px;
  .blue-label {
    color: $color-light-blue0;
  }
  .btn-cancel {
    background-color: white !important;
    width: 96px;
    height: 36px;
    border: solid 1px !important;
  }
  .btn-submit {
    background-color: $color-light-blue3 !important;
    width: 96px;
    height: 36px;
    border: solid 1px $color-light-blue3 !important;
    color: white !important;
  }
}
