$color-dark-blue0: #13233a;
$color-dark-blue1: #182d46;
$color-dark-blue2: #2c3a53;
$color-dark-blue3: #424a61;
$color-dark-blue4: #545b70;
$color-dark-blue5: #6a6d81;
$color-dark-blue6: #808394;
$color-dark-blue7: #999aa8;
$color-dark-blue8: #b5b6c1; //$color-dark-blue-tint2 // default disabled color
$color-dark-blue9: #d6d6dd;

$color-dark-blue-tint0: #ebebed;
$color-dark-blue-tint7: #424a61;

$color-icon-hover: #003453;

$color-light-blue1: #3190d5;
$color-light-blue2: #3499d7;
$color-light-blue0: #0077bf;
$color-light-blue3: #55a4dc;
$color-light-blue4: #72ade1;
$color-light-blue5: #8cb7e4;
$color-light-blue6: #a2c5e9;
$color-light-blue7: #b7d0ee;
$color-light-blue8: #cedff4;
$color-light-blue9: #e5eef9;
$color-light-blue10: #f7f9fc;
$color-light-blue11: #0077bb;
$color-light-blue12: #005fbf;
$color-light-blue13: #3077b9;

$color-light-blue-alt1: #a7c0d4;
$color-light-blue-alt2: #c2d6f2;
$color-light-blue-alt3: #dee9f033;

$color-dark-yellow0: #fbba00;
$color-dark-yellow1: #f9c71c;
$color-dark-yellow2: #fbcd49;
$color-dark-yellow3: #fbd267;
$color-dark-yellow4: #fbd77f;
$color-dark-yellow5: #fcdf94;
$color-dark-yellow6: #fce4a9;
$color-dark-yellow7: #fdeabf;
$color-dark-yellow8: #fef2d2;
$color-dark-yellow9: #fef8e8;

$color-text-alternative0: #ffffff;
$color-text-alternative1: #1d3451;
$color-text-alternative3: #244165;
$color-text-alternative4: #1d34514d;

$color-text-information1: #0e566c;

$color-background0: #efefef;
$color-background1: white;
$color-background2: #fafafb;
$color-background3: #fcfdff;

$color-shadow0: #00000029;

$color-orange0: #fd7125;

$color-success-text: #1e561f;
$color-warning-text: #973937;
$color-error-input: #ed6456;
$color-error-input1: #fbe4dc;
$color-warning-background: #fff6f6;
$color-border-confirmation: #d1d1d1;
$color-error-input: #ed6456;
$color-error-text: #973937;
$color-important-button: #ea594c;
$color-warning-text: #fd7125;
$color-box-shadow: #d4d4d5;
$color-link-hover: #1e70bf;
$color-success-input: #f2fcf6;
$color-success-input1: #01b944;

$color-calendar-white: #e7eef8;

$color-grey-border: rgba(34, 36, 38, 0.15);
$color-grey-background: #f8f8f8;
$black-shadow: rgba(0, 0, 0, 0.16);
