@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";
@import "../../styles/variables.scss";

.ui.container.site-footer {
  margin-top: 1rem;
  width: 100%;
  background: $color-dark-blue0;
  position: relative;
  bottom: 0;
  min-height: 10vh;
  color: $color-text-alternative0;

  h5 {
    font-family: FuturaStd-Medium;
    font-size: 18px;
    font-weight: normal;
  }

  .site-footer-content {
    padding-top: 2.5rem;
    max-width: $app-layout-max-width;
    margin-left: auto;
    margin-right: auto;

    & > .row:last-of-type {
      padding-top: 2rem;
    }
  }
  p {
    color: $color-text-alternative0;
  }
  a {
    color: $color-text-alternative0;
    font-family: FuturaStd-Medium;
  }
}

@media only screen and (max-width: 1023px) {
  .ui.container.site-footer {
    position: relative;
    margin-left: -50vw !important;
    margin-right: -50vw !important;
    margin-top: 300px !important;
    left: 50%;
    right: 50%;
    min-height: 40vh;
    min-width: 100vw;
    margin-top: 80px;

    .site-footer-content {
      padding-bottom: 2.5rem;
    }
  }
}
