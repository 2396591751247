@import "colors.scss";

html {
  body {
    div {
      .ui {
        a.ui.label {
          color: $color-dark-blue2;
          font-family: FuturaStd-Heavy;
        }
      }
    }
  }
}
