.ui.grid > .boxInsightsGrid.row {
  margin-left: 1em;
  margin-right: 1em;

  .ipt-header-text,
  p {
    color: #1d3451;
    font-size: 20px;
    margin: 1.5rem 0rem 0.5rem 0rem;
    font-family: FuturaStd-Heavy;
  }
  .column {
    padding-left: 0rem;
    padding-right: 0rem;
    margin-bottom: 16px;
  }
}

.action-container {
  margin-top: 1.5rem;
  margin-left: auto;
  display: flex;
  flex-direction: row-reverse;
}

.ml2 {
  margin-left: 2px;
}
