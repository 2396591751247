@import "../../styles/colors.scss";

.filter-section {
  margin: 1rem 0rem 4.5rem 0rem;
}

.filter-fields-index-table {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
  margin-bottom: 5px;
  padding-top: 0px;
  padding-right: 0px;
  float: right;
}

.filter-fields-index-table > div > label {
  padding-right: 0.5rem;
}

.ui.table > thead > tr > th.grouped-column-parent {
  border-bottom: none;
  background: #e5eef9;
  color: #51596f;
}

.ui.sortable.table > thead > tr > th.grouped-column-child:nth-child(even) {
  border-left: none;
  background: #e5eef9;
  color: #51596f;
}

.ui.sortable.table > thead > tr > th.grouped-column-child:nth-child(odd) {
  border-left: 1px solid rgba(34, 36, 38, 0.1);
  background: #e5eef9;
  color: #51596f;
}

.ui.icon.button.header-button {
  border: none;
  background-color: white;
}

.ui.icon.button.header-button-clicked {
  color: #13233a;
  border: none;
  background-color: white;
}

.ui.icon.button.header-button-clicked > i.icon {
  font-size: 2em;
}

.ui.icon.button.header-button > i.filter.icon {
  font-size: 2em;
}

.ui.text.loader.table-loader {
  margin-top: 0.5rem;
}

.ui.segment.loader-segment {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.ui.grid > .table-header.row {
  margin-left: 1em;
  margin-right: 1em;

  .ipt-header-text, p {
    color: #1d3451;
    font-size: 20px;
    margin: 1.5rem 0rem 0.5rem 0rem;
    font-family: FuturaStd-Heavy;
  }
  .column {
    border-bottom: 1px solid $color-light-blue-alt2;
    padding-left: 0rem;
    padding-right: 0rem;
    margin-bottom: 16px;
  }
}
