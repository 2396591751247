@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

i.icon.notification-bell {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

div.ui.label:not(.icon).unread-count {
  top: -16px;
  right: -15px;
  background-color: $color-error-input;
  color: $color-background1;
  position: relative;
  font-family: FuturaStd-Heavy;
}

div.notifications-container {
  * {
    display: inline;
  }
  .back-arrow {
    color: $color-light-blue0;
    cursor: pointer;
  }
  .back-arrow:hover {
    color: $color-link-hover;
  }

  display: flex;
  justify-content: space-between;
}

.dashboard-notifications {
  height: 694px;
  overflow-y: auto;
}

div.notification-div {
  width: 380px;
}

button.ui.button.link-button.view-all-button {
  margin-bottom: -20px;
}

div.notifications-container.justify-right {
  justify-content: flex-end;
  padding-top: 1em;
}

div.notifications-list {
  display: block;
  margin-top: 10px;
  padding: 5px;
  border-top: 1px solid #c2d6f2;
}

div.ui.card.notification-card {
  .notification-card-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;

    .heading {
      font-size: 18px;
      font-family: FuturaStd-Heavy;
      color: $color-text-alternative1;
      padding-right: 4px;
    }

    .heading.max-height {
      max-height: 22px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .date {
      .right-arrow {
        color: $color-light-blue0;
        cursor: pointer;
      }
      .right-arrow:hover {
        color: $color-link-hover;
      }

      display: flex;
      font-size: 12px;
      color: $color-dark-blue4;
    }
  }

  .notification-content {
    font-size: 14px;
  }
  .notification-content.max-height {
    max-height: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .notification-action {
    .unread-icon {
      color: $color-light-blue0;
      cursor: pointer;
    }
    .unread-icon:hover {
      color: $color-link-hover;
    }
    .read-icon {
      color: $color-dark-blue4;
    }

    display: flex;
    justify-content: space-between;
  }

  width: 100%;
  box-shadow: 0 1px 3px 0 $color-box-shadow, 0 0 0 0 $color-box-shadow;
  padding: 1em;
  border-left: #fff solid 8px;
  border-radius: 0;
}

div.ui.card.notification-card.unread {
  background: $color-light-blue10;
  border-left: $color-light-blue0 solid 8px;
}

.notification-filter-field {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  & > div.selection {
    margin-left: 10px;
  }
}

div.ui.popup.notification-popup {
  z-index: 6000;
}

@media only screen and (max-width: 688px) {
  div.ui.bottom.center.popup.transition.visible.notification-popup {
    top: 10px !important;
    left: 1px !important;

    &:before {
      left: 74%;
    }
  }

  i.icon.notification-bell {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }

  div.notification-div {
    width: 94vw;
    max-width: 380px;
  }
}

@media only screen and (min-width: 689px) and (max-width: 1023px) {
  div.ui.bottom.center.popup.transition.visible.notification-popup {
    top: 10px !important;
    left: 1px !important;

    &:before {
      left: 74%;
    }
  }

  i.icon.notification-bell {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }

  div.notification-div {
    width: 94vw;
    max-width: 380px;
  }
}
