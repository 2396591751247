@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.terms-conditions {
  .terms-holder {
    max-width: 1500px;
  }

  .ui.segment.terms-container {
    padding: 68px;

    &.cookie-container {
      width: 85% !important;
    }
  }

  .terms-header {
    padding-top: 68px;
    padding-bottom: 68px;
    color: $color-dark-blue0;
    font-family: FuturaStd-Light;
    font-size: 40px;
  }

  p {
    line-height: 24px;
    margin-bottom: 24px;
    color: $color-dark-blue0;
    font-family: FuturaStd-Light;
    font-size: 16px;

    &.extra-space {
      margin-bottom: 48px;
    }
  }
}

@media only screen and (max-width: 688px) {
  .terms-conditions {
    .terms-holder {
      max-width: 95%;

      .ui.segment.terms-container {
        padding: 40px 16px;
        height: auto;
      }

      .terms-header {
        color: #1d3451;
        font-family: FuturaStd-Light;
        font-size: 26px;
        line-height: 26px;
      }
    }
  }
}

@media only screen and (min-width: 689px) and (max-width: 1023px) {
  .terms-conditions {
    .terms-holder {
      max-width: 95%;

      .ui.segment.terms-container {
        padding: 40px 16px;
        height: auto;

        &.cookie-container {
          width: 100% !important;
        }
      }

      .terms-header {
        color: #1d3451;
        font-family: FuturaStd-Light;
        font-size: 26px;
        line-height: 26px;
      }
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1312px) {
  .terms-conditions {
    .terms-holder {
      max-width: 95%;

      .ui.segment.terms-container {
        padding: 40px 16px;
        height: auto;
        width: 78% !important;

        &.cookie-container {
          width: 78% !important;
        }
      }

      .terms-header {
        color: #1d3451;
        font-family: FuturaStd-Light;
        font-size: 26px;
        line-height: 26px;
      }
    }
  }
}
