@import "../../styles/colors.scss";

.index-table-icon-action {
  float: left;
  padding-right: 10px;
  text-align: center;
  color: $color-dark-blue0;
  cursor: pointer;
  .icon-container {
    vertical-align: middle;
    display: inline-flex;

    svg {
      fill: $color-light-blue0;
    }
  }
  .text-container {
    font-size: 10px;
  }
}

.index-table-icon-action.disabled {
  color: $color-dark-blue8;
  .icon-container {
    svg {
      fill: $color-dark-blue8;
    }
  }
}

.index-table-icon-action:hover:not(.disabled) {
  color: $color-icon-hover;

  svg {
    fill: $color-icon-hover;
  }
}
