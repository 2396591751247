@import "../../styles/colors.scss";

.ui.button.back-navigation-button-container {
  padding: 0px !important;
  background-color: white !important;

  &.mobile {
    font-size: 12px;
    color: $color-light-blue0;
  }
}
