@font-face {
  font-family: FuturaStd-Light;
  src: url("fonts/FuturaStdLight.eot");
  src: url("fonts/FuturaStdLight.eot?#iefix") format("embedded-opentype"),
    url("fonts/FuturaStdLight.woff2") format("woff2"),
    url("fonts/FuturaStdLight.woff") format("woff"),
    url("fonts/FuturaStdLight.ttf") format("truetype");
}

@font-face {
  font-family: FuturaStd-Medium;
  src: url("fonts/FuturaStdMedium.eot");
  src: url("fonts/FuturaStdMedium.eot?#iefix") format("embedded-opentype"),
    url("fonts/FuturaStdMedium.woff2") format("woff2"),
    url("fonts/FuturaStdMedium.woff") format("woff"),
    url("fonts/FuturaStdMedium.ttf") format("truetype");
}

@font-face {
  font-family: FuturaStd-Heavy;
  src: url("fonts/FuturaStdHeavy.eot");
  src: url("fonts/FuturaStdHeavy.eot?#iefix") format("embedded-opentype"),
    url("fonts/FuturaStdHeavy.woff2") format("woff2"),
    url("fonts/FuturaStdHeavy.woff") format("woff"),
    url("fonts/FuturaStdHeavy.ttf") format("truetype");
}
