@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.ui.raised.segment.sticky-total-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0px;
  border-radius: 0;
  background-color: $color-light-blue10;
  z-index: 4;

  .footer-main-text {
    font-family: FuturaStd-Light;
    font-size: 15px;
    color: $color-text-alternative1;
  }

  .footer-total-title {
    font-family: FuturaStd-Medium;
    font-size: 16px;
    color: $color-text-alternative1;
  }

  .total-content {
    font-family: FuturaStd-Light;
    font-size: 12px;
    color: $color-text-alternative1;
    padding-top: 4px;
    padding-bottom: 0;
  }

  .show-hide-link {
    font-family: FuturaStd-Medium;
    font-size: 12px;
    color: $color-light-blue0;
  }

  .right-aligned {
    float: right;
  }

  .less-padding {
    padding: 5px;
  }

  .stretch-button {
    flex-grow: 5;
  }

  .fit-buttons {
    display: flex;
  }
}
